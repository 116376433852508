import i18n from '@/plugins/i18n'

function customMessage () {
  if (i18n.locale === 'en') {
    return 'Necessary items, just filled up default value for you.'
  } else {
    return '必填選項，已為您帶入預設值。'
  }
}

function errorMessage (errors, states = false) {
  if (errors.length === 0) return []

  if (states) {
    return customMessage()
  } else {
    return errors
  }
}

export default { errorMessage, customMessage }

import axios from './axios.js'
import store from '@/store'

const baseFilmUrl = `${process.env.VUE_APP_API_BASE_URL}/option/film`
const baseMusicUrl = `${process.env.VUE_APP_API_BASE_URL}/option/music`
const baseCountryUrl = `${process.env.VUE_APP_API_BASE_URL}/option/profile`

export default {
  /**
   * 取得選單
   * @param { JSON } data
   */
  getOptions (params) {
    //  {
    //    category: '',
    //    subcategory: ''
    //  }

    //  category
    //    commercial
    //    noncommercial
    //    location
    //    duration
    //    instrument
    //    genre
    //    emotion
    //    language

    //  subcategory
    //    short
    //    web
    //    drama
    //    theme
    //    ott
    //    podcast
    //    live
    //    global
    //    region
    //    country
    return axios({
      method: 'GET',
      url: `${baseFilmUrl}/`,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json',
        'Accept-Language': params
      }
    })
  },
  getMusicOptions (params) {
    return axios({
      method: 'GET',
      url: `${baseMusicUrl}/`,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json',
        'Accept-Language': params
      }
    })
  },
  getCountryOptions (params) {
    return axios({
      method: 'GET',
      url: `${baseCountryUrl}/`,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json',
        'Accept-Language': params
      }
    })
  }
}

import api from '../api'
const identity = window.sessionStorage.getItem('identity')
const membership = window.sessionStorage.getItem('membership')
export default {
  namespaced: true,
  state: {
    userInfo: {
      // profiles: [
      //   {
      //     id: '',
      //     name: '',
      //     address: '',
      //     id_number: '',
      //     owner: '',
      //     mailing_address: '',
      //     phone: '',
      //     role: ''
      //   }
      // ]
    },
    userIdentity: !identity ? 'musician' : identity,
    membership: membership || 'Business'
  },
  mutations: {
    /**
     * 設定用戶身分
     */
    setUserIdentity (state, value) {
      state.userIdentity = value
    },

    /**
     * 設定用戶資料
     */
    setUserInfo (state, data) {
      // console.log('存入userInfo', data)
      state.userInfo = data
    },

    /**
     * 設定會員方案資料
     */
    setUserMembership (state, data) {
      state.membership = data
    }
  },
  actions: {
    /**
     * 取得用戶資料
     */
    getUserInfo: ({ commit }, payload) => {
      commit('startLoadingCircular', {}, { root: true })
      return new Promise((resolve) => {
        api.user.getUserInfo(payload).then((resp) => {
          /** 沒新增過後端會給空陣列 所以要給vue給預設值去綁v-model */
          if (resp.data.extra_info === null) {
            resp.data.extra_info = {
              location: '',
              introduction: '',
              website: ''
            }
          }
          if (resp.data.profiles.length === 0) {
            resp.data.profiles = [
              {
                id: '',
                name: '',
                address: '',
                id_number: '',
                owner: '',
                mailing_address: '',
                phone: '',
                role: 'individual'
              },
              {
                id: '',
                name: '',
                address: '',
                id_number: '',
                owner: '',
                mailing_address: '',
                phone: '',
                role: 'enterprise'
              }
            ]
          }
          // 如果沒有企業的資料就新增預設資料
          if (resp.data.profiles.findIndex(item => item.role === 'enterprise') === -1) {
            resp.data.profiles.push(
              {
                id: '',
                name: '',
                address: '',
                id_number: '',
                owner: '',
                mailing_address: '',
                phone: '',
                role: 'enterprise'
              }
            )
          }
          commit('stopLoadingCircular', null, { root: true })
          resolve(resp.data)
        })
      })
    },
    /**
     * 編輯用戶資料
     */
    setUserInfo ({ commit }, payload) {
      const obj = {}

      // 如果 profile 有資料就新增到 obj
      if (payload.profile_obj !== undefined) obj.profile = payload.profile_obj
      if (payload.user_obj !== undefined) obj.extra_info = payload.user_obj

      // context.commit('startLoadingCircular', {}, { root: true })
      return new Promise((resolve, reject) => {
        api.user
          .editUserInfo(
            payload.identity,
            payload.role,
            obj
          )
          .then((res) => {
            // commit('setUserInfo', resp.data)
            // commit('stopLoadingCircular', null, { root: true })
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    }
  }
}

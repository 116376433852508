import Vue from 'vue'
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  setInteractionMode,
  configure
} from 'vee-validate'
import {
  required,
  min_value,
  max_value,
  mimes,
  max,
  between
} from 'vee-validate/dist/rules'
import i18n from '../plugins/i18n'

const youtubeLinkRegex = /^(https:\/\/)?(www\.)?(youtube\.com|youtu\.?be|m\.youtube\.com)\/.+$/

const youtube = {
  validate: (value) => {
    return value.match(youtubeLinkRegex)
  }
}
// FIXME 目前全網站maxvalue都被此覆蓋 要修一下QQ
// 客製化驗證訊息
// const maxMsg = {
//   ...max_value,
//   message: i18n.t(
//     'filmMusicMapping.step1.buttonGroup.budgetRange.__validateError'
//   ),
// }
extend('required', { ...required })
extend('min_value', { ...min_value })
extend('max_value', { ...max_value })
extend('mimes', { ...mimes })
extend('max', { ...max })
extend('between', { ...between })
extend('youtube', { ...youtube })

// // Add a rule，此範例中添加了一個名為'secret'的規則，若 value 值不為 'example'，就會回饋 message 裡的文字。
// extend('secret', {
//   validate: (value) => value === 'example',
//   message: 'This is not the magic word',
// })

setInteractionMode('lazy')

configure({
  defaultMessage: (_field, values) => {
    return i18n.t(`validations.${values._rule_}`, values)
  }
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

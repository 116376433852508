export default {
  setCookie (name, value, day) {
    const expires = new Date()
    const expiresDay = day
    expires.setTime(expires.getTime() + expiresDay * 86400 * 1000)
    document.cookie = `${name}=${value};expires=${expires.toGMTString()}`
  },
  getCookie (name) {
    let value = ''
    for (const index in document.cookie.split(';')) {
      if (document.cookie.split(';')[index].split('=')[0].trim() === name) {
        value = document.cookie.split(';')[index].split('=')[1]
      }
    }
    return value
  },
  cleanCookie (name) {
    document.cookie = `${name}=; expire=Thu, 18 Dec 2013 12:00:00 GMT;`
  }
}

import axios from './axios.js'

const baseUrl = `${process.env.VUE_APP_API_BASE_URL}/api/menu`

export default {
  /**
   * 取得選單
   * @param { JSON } data
   */
  getMainMenu (data) {
    const dataPass = {
      menulist: data.menulist
    }
    return axios({
      method: 'POST',
      url: `${baseUrl}/main`,
      data: dataPass
    })
  }
}

import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-free/css/all.css'
import 'font-awesome/css/font-awesome.min.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
// import zhHant from 'vuetify/es5/locale/zh-Hant'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    // locales: { zhHant },
    // current: 'zh-Hant',
    icons: {
      iconfont: ['mdiSvg', 'mdi', 'fa', 'fa4'] // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    }
  }
})

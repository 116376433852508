import axios from './axios.js'
import store from '../store'

const baseUrl = `${process.env.VUE_APP_API_BASE_URL}/music-board`

export default {
  /**
   * 篩選音樂
   */
  filterMusic (payload, url) {
    const params = payload !== '' ? `?${payload}` : payload
    const targetUrl = url !== '' ? url : `${baseUrl}/${params}`
    return axios({
      method: 'GET',
      url: targetUrl,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    })
  },
  /**
   * 取得公開音樂庫的分享碼
   */
  fetchLibraryAuditionURL (payload) {
    return axios({
      method: 'POST',
      url: `${baseUrl}/share/code/`,
      data: payload,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    })
  },
  /**
   * 取得試聽資訊
   */
  fetchLibraryPreviewAudtion (share_code) {
    return axios({
      method: 'GET',
      url: `${baseUrl}/share/preview/${share_code}/`,
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  updateLikeMusic (payload) {
    return axios({
      method: 'POST',
      url: `${baseUrl}/like/`,
      data: payload,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    })
  }
}

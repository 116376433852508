import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zhtw from './tw'
import en from './en'
import zhtwValidationMessages from 'vee-validate/dist/locale/zh_TW'
import enValidationMessages from 'vee-validate/dist/locale/en'

zhtwValidationMessages.messages.youtube = '請放 youtube 連結'
enValidationMessages.messages.youtube = 'Please enter youtube URL'

zhtw.validations = zhtwValidationMessages.messages
en.validations = enValidationMessages.messages

Vue.use(VueI18n)
const lang = !window.localStorage.getItem('lang')
  ? 'zh-tw'
  : window.localStorage.getItem('lang')
export default new VueI18n({
  locale: lang,
  messages: {
    'zh-tw': zhtw,
    en
  }
})

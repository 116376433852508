<template lang="pug">
header(:class='{ blueMode: $store.state.blueMode }')
  .content
    .logo(@click='routeTo({ path: `https://avmapping.co/${currentLocale}` })')
    .right
      .center
        .toolBarBtn(
          v-for='(item, indexI) in toolBarBtn',
          :key='indexI',
          :class='barBtnClass(item)',
          :title='barBtnTitle(item)'
          @click='toolBarHandler(item)'
        )
          template(v-if='item.active')
            .text(v-if='item.displayName') {{ item.name }}
            .icon(v-if='item.icon')
              img(:src='item.icon')
            .subList(v-if='item.sub')
              .subItem(
                v-for='(subItem, indexS) in item.sub',
                :key='indexS',
                :class='currencyClass(subItem.currency)'
                @click='subItemHandler(subItem)'
              ) {{ subItem.name }}
        #google_translate_element
      .user
        .web
          .avatar
            img(:src='require("@/assets/user/defaultAvatar.png")')
          .icon(
            :class='{ musician: $store.state.user.userIdentity === "musician", photographer: $store.state.user.userIdentity === "photographer" }'
          )
          ul.menuList
            li(
              :class='{ onPage: item.path && $store.state.nowPage.indexOf(item.path) !== -1 }',
              v-for='(item, index) in menuList',
              :key='index',
              @click='action(item)'
            ) {{ item.name }}
        .mobile
          .icon(
            :class='{ open: menu.open }',
            @click='() => { menu.open = !menu.open; }'
          )
          .board(:class='{ open: menu.open }')
            .userData
              .avatar
                img(:src='require("@/assets/user/defaultAvatar.png")')
              .data
                .name {{ $store.state.user.userInfo.name }}
                .identity
                  | {{ $store.state.user.userIdentity === "musician" ? $t("all.userIdentity.__musician") : $t("all.userIdentity.__photographer") }}
            .toolBar
              .toolBarBtn(
                :class='{ sub: item.sub, open: item.open }',
                v-for='(item, index) in mobMenuList',
                :key='index',
                @click='mobileListHandler(item, index)'
              )
                span(v-if='mobList[index].name') {{ mobList[index].name }}
                span(v-if='mobList[index].nameIcon', :class='{ icon: mobList[index].nameIcon }')
                  v-icon.icon(color='#e3e3e3') {{ mobList[index].nameIcon }}
                img(v-if='item.icon', :src='item.icon')
            .logout {{ $t("all.mobile.__signOut") }}
</template>

<script>
export default {
  name: 'ToolBar',
  components: {},
  data: () => ({
    menu: { open: false },
    mobMenuList: [
      // 影像配樂
      {
        action: 'path',
        group: 1,
        path: '/dynamicAnalysis'
      },
      // 關於我們
      {
        action: 'main',
        group: 2,
        main: true,
        icon: require('../assets/user/icon/arrow-right.png')
      },
      {
        action: 'path',
        group: 2,
        open: false,
        sub: true,
        path: 'https://avmapping.co/',
        target: ''
      },
      {
        action: 'path',
        group: 2,
        open: false,
        sub: true,
        path: 'https://avmapping.co/',
        target: 'creators/'
      },
      {
        action: 'path',
        group: 2,
        open: false,
        sub: true,
        path: 'https://avmapping.co/',
        target: 'musicians/'
      },
      {
        action: 'path',
        group: 2,
        open: false,
        sub: true,
        path: 'https://avmapping.co/',
        target: 'member/'
      },
      // Q & A
      {
        action: 'path',
        group: 3,
        path: 'https://avmapping.co/',
        target: 'blog/'
      },
      // 幣別
      {
        action: 'main',
        group: 4,
        main: true,
        icon: require('../assets/user/icon/arrow-right.png')
      },
      {
        action: 'currency',
        group: 4,
        open: false,
        sub: true,
        currency: 'TWD'
      },
      {
        action: 'currency',
        group: 4,
        open: false,
        sub: true,
        currency: 'USD'
      },
      {
        action: 'currency',
        group: 4,
        open: false,
        sub: true,
        currency: 'EUR'
      },
      // 語系
      {
        action: 'main',
        group: 5,
        main: true,
        icon: require('../assets/user/icon/arrow-right.png')
      },
      {
        action: 'locale',
        group: 5,
        open: false,
        sub: true,
        locale: 'zh-tw'
      },
      {
        action: 'locale',
        group: 5,
        open: false,
        sub: true,
        locale: 'en'
      },
      // 角色
      {
        action: 'user',
        group: 6,
        path: '/user',
        role: 'film'
      },
      {
        action: 'user',
        group: 6,
        path: '/user',
        role: 'music'
      }
    ],
    user: {
      icon: '',
      path: '/user'
    }
  }),
  computed: {
    menuList () {
      return [
        { name: this.$t('all.navList.user.__film'), path: '/user' },
        { name: this.$t('all.navList.user.__music'), path: '/user' },
        { name: this.$t('all.navList.user.__signOut') }
      ]
    },

    // 按鈕資訊
    toolBarBtn () {
      const locale = this.$i18n.locale === 'en' ? 'en' : 'zh'
      const membership = this.isBusiness
      return [
        // 方案升級
        {
          displayName: true,
          name: this.$t('all.navList.__upgrade'),
          path: `https://avmapping.co/${locale}/member/#section-plan`,
          class: 'upgrade',
          active: membership
        },
        // 影像配樂
        {
          displayName: true,
          name: this.$t('all.navList.__filmMusicMapping'),
          path: '/dynamicAnalysis',
          class: 'analysis',
          active: true
        },
        // 關於我們
        {
          displayName: true,
          name: this.$t('all.navList.__aboutUs'),
          active: true,
          sub: [
            {
              name: this.$t('all.navList.aboutUs.__home'),
              path: `https://avmapping.co/${locale}/`
            },
            {
              name: this.$t('all.navList.aboutUs.__creators'),
              path: `https://avmapping.co/${locale}/creators/`
            },
            {
              name: this.$t('all.navList.aboutUs.__dearMusician'),
              path: `https://avmapping.co/${locale}/musicians/`
            },
            {
              name: this.$t('all.navList.aboutUs.__membership'),
              path: `https://avmapping.co/${locale}/member/`
            }
          ]
        },
        // Q & A
        {
          displayName: false,
          name: this.$t('all.navList.faq.__title'),
          icon: require('../assets/toolbar/icon-ques.svg'),
          path: this.$t('all.navList.faq.__path'),
          active: true
        },
        // 幣別
        {
          displayName: false,
          icon: require('../assets/dynamicAnalysis/v2/s1-budget.svg'),
          active: true,
          sub: [
            {
              name: this.$t('all.navList.currency.__twd'),
              currency: 'TWD'
            },
            {
              name: this.$t('all.navList.currency.__usd'),
              currency: 'USD'
            },
            {
              name: this.$t('all.navList.currency.__eur'),
              currency: 'EUR'
            }
          ]
        },
        // 語系
        {
          displayName: false,
          icon: require('../assets/toolbar/icon-language.svg'),
          active: true,
          sub: [
            { name: '繁體中文', locale: 'zh-tw' },
            { name: 'English', locale: 'en' }
          ]
        }
        // todo: 通知訊息(V2)
        // {
        //   icon: require('../assets/toolbar/icon-notif.svg')
        // }
      ]
    },

    mobList () {
      return [
        // 影像配樂
        { name: this.$t('all.mobile.menu.__filmMusicMapping') },
        // 關於我們
        { name: this.$t('all.mobile.menu.__aboutUs') },
        { name: this.$t('all.mobile.menu.__home') },
        { name: this.$t('all.mobile.menu.__creators') },
        { name: this.$t('all.mobile.menu.__dearMusician') },
        { name: this.$t('all.mobile.menu.__membership') },
        // Q & A
        { nameIcon: 'far fa-question-circle' },
        // 幣別
        { nameIcon: 'fa4 fa-dollar-sign' },
        { name: this.$t('all.navList.currency.__twd') },
        { name: this.$t('all.navList.currency.__usd') },
        { name: this.$t('all.navList.currency.__eur') },
        // 語系
        { nameIcon: 'fa4 fa-globe' },
        { name: '繁體中文' },
        { name: 'English' },
        // 身份
        { name: this.$t('all.mobile.menu.__film') },
        { name: this.$t('all.mobile.menu.__music') }
      ]
    },

    currentLocale () {
      const locale = this.$i18n.locale === 'en' ? 'en/' : 'zh/'
      return locale
    },

    isBusiness () {
      const membership = this.$store.state.user.membership
      if (membership !== 'Business') {
        return true
      } else {
        return false
      }
    }
  },
  mounted () {
    // Add google translate
    const googleTranslateScript = document.createElement('script')
    googleTranslateScript.setAttribute('src', 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit')
    document.head.appendChild(googleTranslateScript)

    window.googleTranslateElementInit = function () {
      // eslint-disable-next-line no-new, no-undef
      new google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element')
    }

    if (localStorage.currency) {
      this.$store.commit('setCurrency', localStorage.currency)
    }
  },
  methods: {
    logoutHref () {
      const homeURL = process.env.VUE_APP_HOME_URL
      const locale = this.$i18n.locale === 'zh-tw' ? 'zh/?logout=true' : 'en/?logout=true'
      return homeURL + locale
    },

    logout () {
      // this.$api.auth.signout().then(() => {
      //   window.location = process.env.VUE_APP_HOME_URL
      // })
      this.$store.dispatch('cleanToken')
      window.location = this.logoutHref()
    },

    /**
     * 執行
     */
    action (item) {
      switch (item.name) {
        case this.$t('all.navList.user.__film'):
          this.$store.commit('user/setUserIdentity', 'photographer')
          window.sessionStorage.setItem('identity', 'photographer')
          this.routeTo(item)
          break
        case this.$t('all.navList.user.__music'):
          this.$store.commit('user/setUserIdentity', 'musician')
          window.sessionStorage.setItem('identity', 'musician')
          this.routeTo(item)
          break
        case this.$t('all.navList.user.__signOut'):
          this.logout()
          break
      }
    },

    /**
     * 工具列按鈕控制器
     */
    toolBarHandler (item) {
      switch (item.name) {
        case this.$t('all.navList.__filmMusicMapping'):
          this.routeTo(item)
          this.$store.commit('user/setUserIdentity', 'photographer')
          break
        case this.$t('all.navList.__upgrade'):
          this.routeTo(item)
          break
        case this.$t('all.navList.faq.__title'):
          window.open(item.path)
          break
      }
    },

    /**
     * 子項目按鈕控制器
     */
    subItemHandler (item) {
      if (item.path) {
        this.routeTo(item)
      } else if (item.locale) {
        this.switchLocale(item)
      } else if (item.currency) {
        this.switchCurrency(item.currency)
      }
    },

    /**
     * 手機清單控制器
     */
    mobileListHandler (item, index) {
      switch (item.action) {
        case 'main':
          this.changeMenuList(item, index)
          break
        case 'user':
          if (item.role === 'film') {
            this.$store.commit('user/setUserIdentity', 'photographer')
            window.sessionStorage.setItem('identity', 'photographer')
            this.routeTo(item)
          } else if (item.role === 'music') {
            this.$store.commit('user/setUserIdentity', 'musician')
            window.sessionStorage.setItem('identity', 'musician')
            this.routeTo(item)
          }
          break
        case 'path':
          // 將資料重組傳給 routeTo method
          this.routeTo({
            path: item.path,
            locale: this.currentLocale,
            target: item.target
          })
          break
        case 'locale':
          this.switchLocale(item)
          break
        case 'currency':
          this.switchCurrency(item.currency)
          break
      }
    },

    /**
     * 更改手機版工具列清單內容
     */
    changeMenuList (item, index) {
      if (item.icon === require('../assets/user/icon/arrow-right.png')) {
        for (const indexL in this.mobMenuList) {
          if (this.mobMenuList[indexL].group === item.group) {
            this.mobMenuList[indexL].open = true
          }
        }
        item.icon = require('../assets/user/icon/arrow-down.png')
      } else {
        for (const indexL in this.mobMenuList) {
          if (this.mobMenuList[indexL].group === item.group) {
            this.mobMenuList[indexL].open = false
          }
        }
        item.icon = require('../assets/user/icon/arrow-right.png')
      }
    },

    /**
     * 切換頁面
     */
    routeTo (item) {
      // 判斷要前往哪個語系的網址
      const targetPath = item.target !== undefined ? `${item.path}${item.locale}${item.target}` : item.path

      if (
        targetPath.indexOf('http') === -1 &&
        targetPath.indexOf('https') === -1
      ) {
        this.$router.push({ path: targetPath })
      } else {
        document.location = targetPath
      }
      this.closeMoblieMenu()
    },

    /**
     * 切換語系
     */
    switchLocale (item) {
      this.$i18n.locale = item.locale
      window.localStorage.setItem('lang', item.locale)
      // window.location.reload()
      // this.closeMoblieMenu()
    },

    /**
     * 切換貨幣
     */
    switchCurrency (currency) {
      this.$store.commit('setCurrency', currency)
      window.localStorage.setItem('currency', currency)
    },

    /**
     * 關閉手機選單
     */
    closeMoblieMenu () {
      for (const index in this.mobMenuList) {
        if (this.mobMenuList[index].open) {
          this.mobMenuList[index].icon =
            this.mobMenuList[index].icon &&
            require('../assets/user/icon/arrow-right.png')
          this.mobMenuList[index].open = false
        }
      }
      this.menu.open = false
    },

    /**
     * 選單類別
     */
    barBtnClass (item) {
      return {
        onPage: this.$store.state.nowPage.indexOf(item.path) !== -1,
        upgrade: item.class === 'upgrade',
        analysis: item.class === 'analysis'
      }
    },

    /**
     * 選單 title 屬性，如果沒有顯示文字就將內容顯示在 title 上
     */
    barBtnTitle (item) {
      if (!item.displayName) {
        return item.name
      }
    },

    /**
     * 貨幣類別
     */
    currencyClass (currency) {
      return {
        active: currency === this.$store.state.currency
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/variables.scss';

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v117/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

header {
  position: fixed;
  background-color: #181818;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  z-index: 99;
  &.blueMode {
    background-color: $dark-5;
  }
  .content {
    width: 1440px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (min-width: 601px) {
      padding: {
        left: 30px;
        right: 30px;
      }
    }
    .logo {
      background: url('../assets/logo.svg') no-repeat center;
      background-size: 140px 37px;
      width: 140px;
      height: 40px;
      cursor: pointer;

      backface-visibility: hidden;
    }
    .right {
      display: flex;
      align-items: center;
      height: 100%;
      .center {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        .toolBarBtn {
          margin-right: 15px;
          display: flex;
          position: relative;
          height: 100%;
          width: auto;
          font-size: 16px;
          letter-spacing: 1.14px;
          text-align: center;
          align-items: center;
          cursor: pointer;
          color: #fefefe;
          > .text {
            height: 40px;
            line-height: 40px;
            border-radius: 4px;
          }
          > .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              display: block;
            }
          }
          &.onPage,
          &.upgrade,
          &.analysis {
            > .text {
              position: relative;
              border-radius: 12px;
              overflow: hidden;
              z-index: 1;
              padding: {
                left: 25px;
                right: 25px;
              }
              &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
              }
            }
          }
          &.onPage,
          &.analysis {
            > .text {
              color: $tealish;
              &::before {
                background-color: $light-navy;
                opacity: 0.3;
              }
            }
          }
          &.upgrade {
            > .text {
              font-size: 18px;
              font-weight: 600;
              color: #181818;
              &::before {
                background: linear-gradient(#4adfd6, #30c1b8);
              }
            }
          }
          &:hover {
            .subList {
              opacity: 1;
              pointer-events: painted;
            }
          }
          .subList {
            position: absolute;
            top: 80px;
            left: 50%;
            transform: translateX(-50%);

            min-width: 130px;

            transition: opacity 0.1s ease-in-out;
            box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);

            pointer-events: none;
            opacity: 0;
            &::before {
              content: '';
              position: absolute;
              left: 50%;
              display: block;
              width: 80%;
              height: 50px;
              transform: translate(-50%, -30px);
              opacity: 0;
            }
            .subItem {
              position: relative;
              display: block;
              text-decoration: none;
              height: 39px;
              line-height: 39px;
              font-size: 14px;
              background-color: #d8d8d8;
              letter-spacing: 1.56px;
              color: #181818;
              border-bottom: solid 1px #979797;
              cursor: pointer;
              transition: color 0.1s ease-in-out;
              transition: background-color 0.1s ease-in-out;
              &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba($light-navy, 0.3);
                transition: opacity 0.1s ease-in-out;
                opacity: 0;
              }
              &:hover {
                color: $tealish;
                background-color: #181818;

                &::before {
                  opacity: 1;
                }
              }
              &:last-of-type {
                border-bottom: none;
              }
              &.active {
                color: $tealish;
                background-color: #181818;

                &::before {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
      .user {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        &:hover {
          .web .menuList {
            opacity: 1;
            z-index: 999;
            pointer-events: painted;
          }
        }
        .web {
          position: relative;
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 100%;
          .avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            z-index: 2;
            img {
              width: 100%;
              height: 100%;
              background-color: $light-navy;
              border-radius: 50%;
            }
          }
          .icon {
            flex-shrink: 0;
            width: 55px;
            height: 40px;
            margin: {
              left: -23px;
            }
            &.photographer {
              background-image: url('../assets/toolbar/navbar-member-video.svg');
              background-repeat: no-repeat;
              background-position: center;
              background-size: 55px 34px;
            }
            &.musician {
              background-image: url('../assets/toolbar/navbar-member-music.svg');
              background-repeat: no-repeat;
              background-position: center;
              background-size: 55px 34px;
            }
          }
          .menuList {
            pointer-events: none;
            position: absolute;
            top: 80px;
            left: calc(50% - 16px);
            transform: translateX(-50%);
            opacity: 0;
            z-index: -1;
            transition: opacity 0.1s ease-in-out;
            list-style-type: none;
            padding-left: 0;
            min-width: 130px;
            box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
            li {
              position: relative;
              white-space: nowrap;
              width: auto;
              padding: 0 26px;
              height: 39px;
              line-height: 39px;
              font-size: 14px;
              font-weight: 500;
              word-spacing: 1.56px;
              text-align: center;
              color: #181818;
              background-color: #d8d8d8;
              border-bottom: solid 1px #979797;
              cursor: pointer;
              transition: color 0.1s ease-in-out;
              transition: background-color 0.1s ease-in-out;

              &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba($light-navy, 0.3);
                transition: opacity 0.1s ease-in-out;
                opacity: 0;
              }

              &:hover {
                color: $tealish;
                background-color: #181818;

                &::before {
                  opacity: 1;
                }
              }

              &:last-of-type {
                border-bottom: none;
              }
            }
          }
        }
        .mobile {
          display: none;
          position: relative;
          > .icon {
            cursor: pointer;
            margin-right: 15px;
            background: url('../assets/toolbar/hamburger.png') no-repeat center;
            background-size: 23px 19px;
            width: 25px;
            height: 20px;
            &.open {
              background: url('../assets/toolbar/hamburger02.png') no-repeat
                center;
              background-size: 23px 19px;
            }
          }
          .board {
            overflow-y: scroll;
            position: absolute;
            top: 35px;
            right: 0;
            width: 0;
            height: 100vh;
            padding-bottom: 72px;
            border-left: none;
            background-color: #181818;
            z-index: 999;
            transition: width 0.2s ease-in-out;
            &.open {
              width: 299px;
              border-left: solid 1px rgba(151, 151, 151, 0.1);
            }
            .userData {
              margin-top: 42px;
              display: flex;
              .avatar {
                margin-left: 19px;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                }
              }
              .data {
                margin-left: 21px;
                .name {
                  height: 28px;
                  font-size: 20px;
                  font-weight: 500;
                  line-height: 28px;
                  letter-spacing: 3px;
                  color: rgba(216, 216, 216, 0.9);
                }
                .identity {
                  margin-top: 4px;
                  height: 18px;
                  font-size: 13px;
                  line-height: 18px;
                  letter-spacing: 0.5px;
                  color: rgba(216, 216, 216, 0.5);
                }
              }
            }
            .toolBar {
              margin-top: 16px;
              .toolBarBtn {
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 16px 0 20px;
                width: 100%;
                height: 50px;
                line-height: 50px;
                &.sub {
                  overflow: hidden;
                  height: 0;
                  line-height: 0px;
                  background-color: #202020;
                  z-index: -1;
                  transition: height 0.2s ease-in-out;
                  &.open {
                    height: 50px;
                    line-height: 50px;
                    border-bottom: solid 1px rgba(216, 216, 216, 0.15);
                  }
                }
                span {
                  display: block;
                  height: 20px;
                  font-size: 14px;
                  line-height: 20px;
                  letter-spacing: 1px;
                  color: rgba(216, 216, 216, 0.9);
                  &.icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                  }
                }
                img {
                  width: 13px;
                  height: 13px;
                }
              }
            }
            .logout {
              margin: 20px auto 0;
              text-align: center;
              cursor: pointer;
              width: 260px;
              height: 36px;
              line-height: 36px;
              border-radius: 3px;
              border: solid 1px rgba(216, 216, 216, 0.3);
              font-size: 14px;
              letter-spacing: 0.7px;
              color: rgba(216, 216, 216, 0.6);
            }
          }
        }
      }
    }
  }

  #google_translate_element {
    margin-right: 15px;

    ::v-deep .goog-te-gadget {

      font-size: 0;

      > div:first-child {
        position: relative;

        &::before {
          content: '';

          display: block;
          width: 30px;
          height: 30px;

          font-family: 'Material Icons';
          font-size: 20px;
          color: #fefefe;
          text-align: center;

          border-radius: 50%;
        }

        > select {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;

          opacity: 0;

          margin: {
            top: 0;
            bottom: 0;
          }
        }
      }

      > span {
        display: none;
      }
    }
  }
}

@media (max-width: 1440px) {
  header {
    .content {
      width: 100%;
    }
  }
}

// @media (max-width: 960px) {
//   header {
//     width: 100%;
//     .logo {
//       margin-left: 20px;
//     }
//     .center {
//       display: none;
//     }
//     .menuBtn {
//       display: block;
//     }
//   }
// }

@media (max-width: 600px) {
  header {
    height: 50px;
    .content {
      .logo {
        margin-left: 15px;
        background-size: 98px 27px;
        width: 98px;
        height: 27px;
      }
      .right {
        .center {
          display: none;
        }
        .user {
          .web {
            display: none;
          }
          .mobile {
            display: block;
          }
        }
      }
    }
  }
}
</style>

import axios from './axios.js'

const baseUrl = `${process.env.VUE_APP_API_BASE_URL}/api/main`

export default {
  /**
   * 取得分析結果
   * @param { JSON } data
   * @param { function } uploadProgressCallback
   */
  server_to_AVMA (data, uploadProgressCallback) {
    const dataPass = {
      video_name: data.videoName,
      video_url: data.videoUrl,
      outline: data.outline,
      ref_music: data.refMusic,
      budget: data.budget,
      music_spec: data.musicSpec,
      license_usage: data.licenseUsage,
      license_range: data.licenseRange,
      license_time: data.licenseTime
    }
    return axios({
      method: 'POST',
      url: `${baseUrl}/server2AVMA`,
      data: dataPass,
      onUploadProgress: (event) => {
        uploadProgressCallback(event)
      }
    })
  }
}

import axios from './axios.js'
import store from '../store'
import Request from 'axios-request-handler'

const baseUrl = `${process.env.VUE_APP_API_BASE_URL}/film`

const makeFormdata = (data) => {
  const passData = new FormData()
  Object.keys(data).forEach((key) => {
    passData.append(key, data[key])
  })
  return passData
}

export default {
  /**
   * 取得影片清單
   */
  getFilmList (params, currency) {
    return axios({
      method: 'GET',
      url: `${baseUrl}/?${params}`,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json',
        'Avma-Currency': currency
      }
    })
  },
  getFilm (id, currency) {
    return axios({
      method: 'GET',
      url: `${baseUrl}/${id}/`,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json',
        'Avma-Currency': currency
      }
    })
  },
  pollFilm (id) {
    const poller = new Request(`${baseUrl}/${id}/`, {
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    })

    return poller
  },
  updateFilm (payload) {
    const { id, data } = payload
    return axios({
      method: 'PATCH',
      url: `${baseUrl}/${id}/`,
      data,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    })
  },
  deleteFilm (id) {
    return axios({
      method: 'DELETE',
      url: `${baseUrl}/${id}/`,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    })
  },
  /**
   * 取得影片媒合歌曲清單
   * @param { JSON } data
   */
  getMappinglist (data) {
    const dataPass = {
      film_id: data.filmId
    }
    return axios({
      method: 'POST',
      url: `${baseUrl}/getmappinglist`,
      data: dataPass
    })
  },
  uploadFilm (data, uploadProgressCallback) {
    const params = makeFormdata(data)
    return axios({
      method: 'POST',
      url: `${baseUrl}/`,
      data: params,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (event) => {
        uploadProgressCallback(event)
      }
    })
  },
  createFilmToken (id, params, currency) {
    return axios({
      method: 'POST',
      url: `${baseUrl}/${id}/service/`,
      data: params,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json',
        'Avma-Currency': currency
      }
    })
  },
  createFilmData (id, sessionId) {
    return axios({
      method: 'GET',
      url: `${baseUrl}/${id}/${sessionId}/`,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    })
  },
  patchFilmData (id, sessionId, params) {
    return axios({
      method: 'PATCH',
      url: `${baseUrl}/${id}/${sessionId}/`,
      data: params,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    })
  },
  /**
   * 篩選漏斗
   * @param { JSON } data
   */
  patchLikeMusic (id, payload) {
    return axios({
      method: 'PATCH',
      url: `${baseUrl}/${id}/like-music/`,
      data: payload,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    })
  },
  /**
   * 取得服務設定
   */
  getServiceSetting (id, currency) {
    return axios({
      method: 'GET',
      url: `${baseUrl}/${id}/service/`,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json',
        'Avma-Currency': currency
      }
    })
  },
  /**
   * 取得請求服務結果
   */
  getServiceResult (id, sessionId, currency) {
    return axios({
      method: 'GET',
      url: `${baseUrl}/${id}/service/${sessionId}/`,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json',
        'Avma-Currency': currency
      }
    })
  },
  /**
   * 上傳照片
   */
  uploadImage (data, uploadProgressCallback) {
    const params = makeFormdata(data)
    return axios({
      method: 'POST',
      url: `${process.env.VUE_APP_API_BASE_URL}/image/`,
      data: params,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (event) => {
        uploadProgressCallback(event)
      }
    })
  },
  /**
   * 取得配樂的 buffer
   */
  fetchSound (url) {
    return axios({
      method: 'GET',
      responseType: 'arraybuffer',
      url
    })
  },
  /**
   * 取得配樂的 buffer
   */
  fetchSoundBlob (url) {
    return axios({
      method: 'GET',
      responseType: 'blob',
      url
    })
  },
  /**
   * 取得分享碼
   */
  fetchAuditionURL (film_id, payload) {
    return axios({
      method: 'POST',
      url: `${baseUrl}/${film_id}/share-code/`,
      data: payload,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    })
  },
  /**
   * 取得試聽資料
   */
  fetchPreviewAudition (share_code) {
    return axios({
      method: 'GET',
      url: `${baseUrl}/preview/${share_code}/`,
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  /**
   * 匯出影片設定
   */
  exportMixerRecord (payload) {
    const { id, params } = payload
    return axios({
      method: 'POST',
      url: `${baseUrl}/${id}/export-record/`,
      data: params,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    })
  }
}

<template lang="pug">
v-dialog(
  content-class='alert',
  v-model='$store.state.alert.open',
  persistent
)
  .board(v-if='inAnalysis === false')
    .board-container
      .warn
      .content(v-if='content') {{ content }}
      .note(v-if='note') {{ note }}
    .buttons
      button(v-if='cancelText' @click='cancel()') {{ cancelText }}
      button(@click='confirm()') {{ confirmText }}
  .board.board-inAnalysis(v-else)
    .board-container
      .warn
      .content(v-if="analysisContent") {{ analysisContent }}
      .note(v-if="analysisNote") {{ analysisNote }}
    .buttons
      button(@click='newAnalysis()') {{ confirmButton1 }}
      button(@click='confirm()') {{ confirmButton2 }}
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'theAlert',
  computed: {
    ...mapState({
      content: (state) => state.alert.content,
      note: (state) => state.alert.note,
      analysisContent: (state) => state.alert.analysisContent,
      analysisNote: (state) => state.alert.analysisNote,
      cancelText: (state) => state.alert.cancelText,
      confirmText: (state) => state.alert.confirmText,
      confirmButton1: (state) => state.alert.confirmButton1,
      confirmButton2: (state) => state.alert.confirmButton2,
      inAnalysis: (state) => state.analyzer.inAnalysis
    })
  },
  methods: {
    cancel () {
      this.$store.commit('closeAlert', false)
    },
    confirm () {
      this.$store.commit('closeAlert', true)
    },
    newAnalysis () {
      const currentData = this.$router.resolve({ name: 'step1' })
      const newWindow = window.open(currentData.href, '_blank', 'noopener, noreferrer')
      if (newWindow) newWindow.opener = null
    }
  }
}
</script>

<style lang="scss">
.alert {
  max-width: 380px;
  width: 100%;
  &.v-dialog {
    border-radius: 25px;
  }
  .board {
    width: 100%;
    height: auto;
    background-color: #313335;
    text-align: center;
    overflow: hidden;
    &-container {
      padding: {
        top: 20px;
        left: 20px;
        right: 20px;
        bottom: 20px;
      }
    }
    &-inAnalysis {
      .note {
        text-align: left;
      }
    }
    .warn {
      width: 30px;
      height: 30px;
      background-image: url('../assets/public/warning.svg');
      background-repeat: no-repeat;
      background-position: center;

      margin: {
        left: auto;
        right: auto;
      }
    }
    .content {
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 0.03em;
      line-height: 1.7;
      color: #fff;
      white-space: pre-wrap;

      padding: {
        top: 10px;
      }
    }
    .note {
      font-size: 15px;
      font-weight: 300;
      line-height: 1.6;
      letter-spacing: 0.05em;
      color: #d8d8d8;
      white-space: pre-wrap;
    }
    .buttons {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      overflow: hidden;
    }
    button {
      flex: 1;
      font-size: 16px;
      line-height: 1.4;
      color: #70bebb;

      outline: none;
      transition: color 0.3s ease-in-out;

      border: {
        top: solid 3px rgba(#3e4145, 0.5);
      }

      padding: {
        top: 15px;
        left: 20px;
        right: 20px;
        bottom: 15px;
      }

      &:hover {
        color: #fff;
      }

      &:nth-of-type(2) {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: -1.5px;
          width: 3px;
          height: 100%;
          background-color: rgba(#3e4145, 0.5);
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .alert {
    .board {
      .content {
        font-size: 16px;
      }
      button {
        font-size: 14px;
      }
    }
  }
}
</style>

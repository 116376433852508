import Vue from 'vue'
import Video from 'video.js'
import i18n from './plugins/i18n'
import 'video.js/dist/video-js.css'
import app from './app.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { stringify } from './plugins/qs'
import api from './api'
import formatTime from './plugins/formatTime'
import cookie from './plugins/cookie'
import './plugins/rem'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import './plugins/vee-validate'
import './filters'
import customErrors from './plugins/customErrors'

Vue.config.productionTip = false
Vue.prototype.$video = Video
Vue.prototype.$api = api
Vue.prototype.$formatTime = formatTime
Vue.prototype.$cookie = cookie
Vue.prototype.$qs = stringify
Vue.prototype.$customErrors = customErrors

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new VueIntegration({ Vue, attachProps: true })],
  environment: process.env.NODE_ENV
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(app)
}).$mount('#app')

if (process.env.NODE_ENV === 'development' || window.Cypress) {
  window.$store = store
}

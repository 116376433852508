import axios from './axios.js'

const baseUrl = '/testData/tradeDetails'

export default {
  /**
   * 取得查詢項目
   */
  getQueryItem () {
    return axios({
      method: 'get',
      url: `${baseUrl}/queryItem.json`
    })
  },

  /**
   * 取得明細類型
   */
  getDetailsType () {
    return axios({
      method: 'get',
      url: `${baseUrl}/detailsType.json`
    })
  },

  /**
   * 查詢交易明細
   */
  queryTradeDetails () {
    return axios({
      method: 'get',
      url: `${baseUrl}/tradeRecord.json`
    })
  }
}

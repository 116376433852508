import axios from './axios.js'
import store from '../store'

const baseUrl = `${process.env.VUE_APP_API_BASE_URL}/music`

const makeFormdata = (data) => {
  const passData = new FormData()
  Object.keys(data).forEach((key) => {
    passData.append(key, data[key])
  })
  return passData
}

export default {
  /**
   * 上傳歌曲
   * @param { JSON } data
   * @param { function } uploadProgressCallback
   * @param { String } currency
   */
  uploadSong (data, uploadProgressCallback, currency) {
    const params = makeFormdata(data)
    return axios({
      method: 'POST',
      url: `${baseUrl}/`,
      data: params,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Avma-Currency': currency,
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (event) => {
        uploadProgressCallback(event)
      }
    })
  },

  /**
   * 取得歌曲清單
   */
  getSongList (page) {
    return axios({
      method: 'GET',
      url: `${baseUrl}/?${page}`,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    })
  },

  /**
   * 取得歌曲詳細資料
   * @param { JSON } data
   * @param { String } currency
   */
  getSongDetail (data, currency) {
    return axios({
      method: 'GET',
      url: `${baseUrl}/${data.musicId}/`,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json',
        'Avma-Currency': currency
      }
    })
  },
  /**
   * 取得歌曲詳細資料
   * @param { JSON } data
   * @param { String } currency
   */
  updateSong ({ id, ...data }, currency) {
    return axios({
      method: 'PATCH',
      url: `${baseUrl}/${id}/`,
      data,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json',
        'Avma-Currency': currency
      }
    })
  },
  /**
   * 刪除歌曲
   * @param { JSON } data
   */
  deleteSong (data) {
    return axios({
      method: 'DELETE',
      url: `${baseUrl}/${data.musicId}/`,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    })
  },
  /**
   * IPFS 訂閱流程
   */
  uploadIPFS (id, params, currency) {
    return axios({
      method: 'POST',
      url: `${baseUrl}/${id}/ipfs/`,
      data: params,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Avma-Currency': currency,
        'Content-Type': 'application/json'
      }
    })
  },
  /**
   * 取消 IPFS
   */
  cancelIPFS (id, params) {
    return axios({
      method: 'POST',
      url: `${baseUrl}/${id}/ipfs/cancel/`,
      data: params,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    })
  }
}

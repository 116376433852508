import Vue from 'vue'
import { merge } from 'lodash'

const initialState = function () {
  return {
    flow: 'initial',
    cart: [],
    film: {},
    formData: {},
    fromFilms: '',
    order: [],
    orderID: '',
    preview: '',

    analysisCount: 0, // 已分析次數
    debugMode: false, // 開發模式
    inAnalysis: false, // 分析中
    promo: {}, // 優惠碼
    reanalyze: false, // V2 重新分析狀態,
    selectedService: [], // 選擇的服務

    spec: {
      bpm: 0,
      keyword: '',
      language: null,
      other: '',
      outline: '',
      refMusic: '',
      selectedEffect: [],
      selectedGenres: [],
      selectedInstruments: [],
      selectedMoods: []
    },
    payInfo: {
      realName: null
    },

    // analyzer mixer data
    mixer: {
      audioContext: null,
      currentTime: 0,
      gainNode: null,
      playing: false,
      soloTracks: [],
      trackLoaded: true,
      tracksMap: {
        /**
         * format
         * id : {
         *  dragging: Boolean,
         *  duration: Number
         *  pausedAt: Number,
         *  restart: Boolean,
         *  startedAt: Number,
         *  trackPausedAt: Number,
         * }
         */
      }
    },
    // 混音組合
    mixerOrder: [],
    // 匯出 mixer 設定後的回傳參數
    mixerRecord: [],
    // 下載混音組合的類型
    recordType: 'video'
  }
}

const state = initialState()

const getters = {}
const mutations = {
  SET_PREVIEW (state, payload) {
    state.preview = payload
  },
  SET_ANALYSIS (state, payload) {
    state.analysis = merge(state.analysis, payload)
  },
  SET_FILM (state, payload) {
    state.film = payload
  },
  UPDATE_FILM_VALUE (state, payload) {
    const { key, value } = payload
    state.film[key] = value
  },
  SET_TITLE (state, payload) {
    state.film.title = payload
  },
  SET_SELECTED_INSTRUMENTS (state, payload) {
    state.spec.selectedInstruments = [...payload]
  },
  SET_SELECTED_GENRES (state, payload) {
    state.spec.selectedGenres = [...payload]
  },
  SET_SELECTED_MOODS (state, payload) {
    state.spec.selectedMoods = [...payload]
  },
  SET_SELECTED_EFFECT (state, payload) {
    state.spec.selectedEffect = [...payload]
  },
  SET_BPM (state, payload) {
    state.spec.bpm = payload
  },
  SET_LANGUAGE (state, payload) {
    state.spec.language = payload
  },
  SET_OTHER (state, payload) {
    state.spec.other = payload
  },
  SET_REF_MUSIC (state, payload) {
    state.spec.refMusic = payload
  },
  SET_OUTLINE (state, payload) {
    state.spec.outline = payload
  },
  SET_KEYWORD (state, payload) {
    state.spec.keyword = payload
  },
  ADD_TO_CART (state, payload) {
    state.cart = [
      ...state.cart.filter((item) => item.id !== payload.id),
      payload
    ]
  },
  REMOVE_FROM_CART (state, payload) {
    state.cart = [...state.cart.filter((item) => item.id !== payload.id)]
  },
  REMOVE_EFFECT_FROM_CART (state, payload) {
    state.cart = [...state.cart.filter((item) => item.title !== payload.title)]
  },
  MODIFY_CART_ITEM (state, payload) {
    const index = state.cart.findIndex(item => item.id === payload.id)
    state.cart[index] = payload
  },
  // 移除優惠價格
  REMOVE_CART_PROMO (state) {
    state.cart.forEach(item => { item.promo = undefined })
  },
  // 儲存優惠碼狀態
  SET_PROMO (state, payload) {
    state.promo = payload
  },
  // 重製優惠
  RESET_PROMO (state) {
    state.promo = {}
  },
  // 重置購物車
  RESET_CART (state) {
    state.cart = []
  },
  // 比對購物車跟已購買的配樂，已購買過的不再顯示可以勾選的狀態
  BOUGHT_FROM_CART (state, payload) {
    const boughtSet = payload.items.map(item => item.id)
    state.cart.forEach(item => {
      if (item.service === 'soundtrack' && boughtSet.includes(item.music_id)) {
        item.isBought = true
      } else if (item.service === 'reduction' && boughtSet.includes(item.reduce_id)) {
        item.isBought = true
      } else {
        item.isBought = false
      }
    })
  },
  // 將配樂從 order 裡面移除
  REMOVE_FROM_ORDER (state, payload) {
    state.order = [...state.order.filter((item) => item.id !== payload.id)]
  },
  SET_ORDER (state, payload) {
    state.order.push(payload)
  },
  REMOVE_ORDER (state) {
    state.order = []
  },
  SET_ORDER_ID (state, payload) {
    state.orderID = payload
  },
  // SET_REAL_NAME(state, payload) {
  //   state.realName = payload
  // },
  SET_PAY_INFO (state, payload) {
    state.payInfo = payload
  },
  RESET_STATE (state) {
    const defaultState = initialState()
    Object.keys(defaultState).forEach((key) => {
      state[key] = defaultState[key]
    })
  },
  SET_FORM (state, payload) {
    state.formData = payload
  },
  // 判斷是否從影像庫過來
  SET_FROM_FILMS_STATE (state, payload) {
    state.fromFilms = payload
  },
  SET_ANALYSIS_STATE (state, payload) {
    state.inAnalysis = payload
  },
  SET_ANALYSIS_COUNT (state, payload) {
    state.analysisCount = payload
  },
  SET_SERVICE (state, payload) {
    state.selectedService = payload
  },
  SET_REANALYZE (state, payload) {
    state.reanalyze = payload
  },
  SET_FLOW (state, payload) {
    /**
     * @param {string}
     * initial, sync, village
     */
    state.flow = payload
  },

  // mixer method - start
  SET_MIXER (state, payload) {
    Object.keys(payload).forEach(item => {
      state.mixer[item] = payload[item]
    })
  },
  SET_MIXER_PLAYING (state, payload) {
    state.mixer.playing = payload
  },
  SET_MIXER_CURRENT_TIME (state, payload) {
    state.mixer.currentTime = payload
  },
  ADD_MIXER_TRACKS_MAP (state, payload) {
    const { dragging, duration, id, pausedAt, restart, startedAt, trackPausedAt } = payload

    Vue.set(state.mixer.tracksMap, id, {
      dragging,
      duration,
      pausedAt,
      restart,
      startedAt,
      trackPausedAt
    })
  },
  REMOVE_MIXER_TRACKS_MAP_ITEM (state, key) {
    delete state.mixer.tracksMap[key]
  },
  SET_MIXER_TRACKS_DATA (state, payload) {
    const { id } = payload

    Object.entries(payload).forEach(item => {
      if (item[0] === 'id') return
      state.mixer.tracksMap[id][item[0]] = item[1]
    })
  },
  SET_MIXER_TRACKS_DRAGGING (state, payload) {
    const { id, dragging } = payload
    state.mixer.tracksMap[id]['dragging'] = dragging
  },
  // SET_MIXER_TRACKS_PAUSED (state, payload) {
  //   const { dragging, id, pausedAt, restart, trackPausedAt } = payload
  //   state.mixer.tracksMap[id]['dragging'] = dragging
  //   state.mixer.tracksMap[id]['pausedAt'] = pausedAt
  //   state.mixer.tracksMap[id]['restart'] = restart
  //   state.mixer.tracksMap[id]['trackPausedAt'] = trackPausedAt
  // },
  SET_ALL_MIXER_TRACKS_PAUSED (state, payload) {
    const { dragging, pausedAt, restart, trackPausedAt } = payload
    const keys = Object.keys(state.mixer.tracksMap)
    keys.forEach(item => {
      state.mixer.tracksMap[item]['dragging'] = dragging
      state.mixer.tracksMap[item]['pausedAt'] = pausedAt
      state.mixer.tracksMap[item]['restart'] = restart
      state.mixer.tracksMap[item]['trackPausedAt'] = trackPausedAt
    })
  },
  SET_MIXER_TRACKS_RESTART (state, payload) {
    const { id, restart } = payload
    state.mixer.tracksMap[id]['restart'] = restart
  },
  RESET_MIXER_TRACKS_MAP (state) {
    state.mixer.tracksMap = {}
  },
  ADD_MIXER_SOLO_TRACKS (state, payload) {
    state.mixer.soloTracks.push(payload)
  },
  REMOVE_MIXER_SOLO_TRACKS (state, payload) {
    state.mixer.soloTracks = [...state.mixer.soloTracks.filter(item => item !== payload)]
  },
  SET_TRACK_LOADED (state, payload) {
    state.mixer.trackLoaded = payload
  },
  // mixer method - end
  SET_MIXER_ORDER (state, payload) {
    state.mixerOrder = payload
  },
  SET_MIXER_RECORD (state, payload) {
    state.mixerRecord = payload
  },
  SET_RECORD_TYPE (state, payload) {
    state.recordType = payload
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

import axios from 'axios'
import store from '../store'
import i18n from '../plugins/i18n'

const instence = axios.create({
  timeout: 1000 * process.env.VUE_APP_API_TIMEOUT
})

// 異常處理
instence.interceptors.response.use(
  (response) => {
    return response
  },
  (err) => {
    store.commit('stopLoadingCircular')
    if (err && err.response) {
      switch (err.response.status) {
        case 401:
          console.log('登入錯誤')
          store.commit('callAlert', {
            content: i18n.t('errors.__login'),
            confirmCallback: function () {
              store.commit('toggleSignView', {
                page: 'login',
                path: window.location.hash.split('#')[1],
                state: true
              })
            }
          })
          break
        case 404:
          console.log('找不到該頁面')
          break
        case 406:
          console.log('登入錯誤')
          store.commit('callAlert', {
            content: i18n.t('errors.__login'),
            confirmCallback: function () {
              store.commit('toggleSignView', {
                page: 'login',
                path: window.location.hash.split('#')[1],
                state: true
              })
            }
          })
          break
        case 500:
          console.log('伺服器出錯')
          break
        case 503:
          console.log('服務失效')
          break
        default:
          console.log(`連接錯誤，錯誤代碼 ${err.response.status}`)
          if (Array.isArray(err.response.data.message)) {
            errorAlert(err.response.data.message[0])
          } else {
            errorAlert(err.response.data.message)
          }
      }
      return Promise.reject(err.response)
    } else {
      console.log(err)
      console.log('連接伺服器失敗')
    }
    return Promise.resolve(err.response)
  }
)

const errorAlert = (err) => {
  switch (err) {
    case 'Unavailable subscription membership':
      store.commit('callAlert', {
        content: i18n.t('errors.__unavailableSubscription'),
        confirmCallback: () => {
          const locale = i18n.locale === 'zh-tw' ? 'zh' : 'en'
          window.location.href = `https://avmapping.co/${locale}/member/#section-plan`
        }
      })
      break

    case 'Insufficient analyze count':
      store.commit('callAlert', {
        content: i18n.t('errors.__unavailableSubscription'),
        confirmCallback: () => {
          const locale = i18n.locale === 'zh-tw' ? 'zh' : 'en'
          window.location.href = `https://avmapping.co/${locale}/member/#section-plan`
        }
      })
      break

    case 'The email needs to be validated':
      store.commit('callAlert', {
        content: i18n.t('errors.__validated')
      })
      break

    case 'The Youtube url is invalid':
      store.commit('callAlert', {
        content: i18n.t('errors.__invalid')
      })
      break

    case 'The Youtube url is unavailable':
      store.commit('callAlert', {
        content: i18n.t('errors.__unavailable')
      })
      break

    case 'Unknown error when downloading the YouTube URL':
      store.commit('callAlert', {
        content: i18n.t('errors.__unknown')
      })
      break

    case 'The mixer record data is missing':
      store.commit('callAlert', {
        content: i18n.t('errors.__mixerMissing')
      })
      break

    case 'unavailable music':
      store.commit('callAlert', {
        content: i18n.t('errors.__unavailableMusic')
      })
      break

    case 'unavailable file':
      store.commit('callAlert', {
        content: i18n.t('errors.__unavailableFile')
      })
      break

    case 'unavailable sound effect':
      store.commit('callAlert', {
        content: i18n.t('errors.__unavailableEffect')
      })
      break
  }
}

export const makeFormdata = (data) => {
  const passData = new FormData()
  Object.keys(data).forEach((key) => {
    passData.append(key, data[key])
  })
  return passData
}

export default instence

import Vue from 'vue'
import Vuex from 'vuex'
import cookie from '../plugins/cookie'
import i18n from '../plugins/i18n'
import createPersistedState from 'vuex-persistedstate'

import user from './user'
import analyzer from './analyzer'

import zipy from 'zipyai'
if (process.env.NODE_ENV !== 'development') {
  zipy.init('70be06e4')

  if (cookie.getCookie('token')) {
    zipy.identify(cookie.getCookie('token'))
  }
}

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sign: {
      page: '',
      path: '',
      state: false
    },
    token: cookie.getCookie('token') || '',
    alert: {
      open: false,
      content: '',
      note: '',
      analysisContent: '',
      analysisNote: '',
      cancelText: '',
      confirmText: i18n.t('system.alert.__confirm'),
      confirmButton1: '',
      confirmButton2: '',
      confirmCallback: function () {},
      cancelCallback: function () {}
    },
    loadingCircular: {
      start: false,
      text: ''
    },
    nowPage: '',
    filmSocket: {},
    lackOfMusic: {
      status: false
    },
    blueMode: false,
    currency: ''
  },
  mutations: {
    /**
     * 設定 token
     * @param {*} state
     * @param {*} value
     */
    setToken (state, value) {
      state.token = value
      cookie.setCookie('token', value, 2)
    },

    /**
     * 清除 token
     * @param {*} state
     */
    cleanToken (state) {
      cookie.cleanCookie('token')
      state.token = ''
    },

    /**
     * 開啟需確認通知
     * @param {*} state
     * @param {*} value
     */
    callAlert (state, value) {
      state.alert.open = true
      state.alert.content = value.content ? value.content : ''
      state.alert.note = value.note ? value.note : ''
      state.alert.analysisContent = value.analysisContent ? value.analysisContent : ''
      state.alert.analysisNote = value.analysisNote ? value.analysisNote : ''
      state.alert.confirmButton1 = value.confirmButton1 ? value.confirmButton1 : ''
      state.alert.confirmButton2 = value.confirmButton2 ? value.confirmButton2 : ''
      state.alert.cancelText = value.cancelText ? value.cancelText : ''
      state.alert.confirmText = value.confirmText
        ? value.confirmText
        : i18n.t('system.alert.__confirm')
      state.alert.confirmCallback = value.confirmCallback
        ? value.confirmCallback
        : function () {}
      state.alert.cancelCallback = value.cancelCallback
        ? value.cancelCallback
        : function () {}
    },

    /**
     * 關閉需確認通知
     * @param {*} state
     */
    closeAlert (state, value = true) {
      state.alert.open = false
      state.alert.content = ''
      state.alert.confirmText = i18n.t('system.alert.__confirm')
      if (value) {
        state.alert.confirmCallback()
      } else {
        state.alert.cancelCallback()
      }
    },

    /**
     * 開啟讀取畫面
     * @param {*} state
     * @param {*} value
     */
    startLoadingCircular (state, value) {
      state.loadingCircular.start = true
      state.loadingCircular.text = value.text ? value.text : ''
    },

    /**
     * 關閉讀取畫面
     * @param {*} state
     */
    stopLoadingCircular (state) {
      state.loadingCircular.start = false
      state.loadingCircular.text = ''
    },

    /**
     * 紀錄當前頁面
     * @param {*} state
     * @param {*} value
     */
    setNowPage (state, value) {
      state.nowPage = value
    },

    setFilmSocket (state, payload) {
      state.filmSocket = payload
    },

    toggleLackOfMusic (state) {
      state.lackOfMusic.status = !state.lackOfMusic.status
    },

    resetAlertCallback (state) {
      state.alert.confirmCallback = () => {}
      state.alert.cancelCallback = () => {}
    },

    isBlueMode (state, payload) {
      state.blueMode = payload
    },

    setCurrency (state, payload) {
      state.currency = payload
    },

    toggleSignView (state, { page, path, state: signState }) {
      state.sign.state = signState

      if (page) state.sign.page = page
      if (path) state.sign.path = path
    }
  },
  actions: {
    setToken (context, value) {
      return new Promise((resolve) => {
        context.commit('setToken', value)
        resolve()
      })
    },
    cleanToken (context) {
      context.commit('cleanToken')
    }
  },
  modules: {
    user,
    analyzer
  },
  plugins: [
    createPersistedState({
      key: 'analyzer',
      paths: ['analyzer'],
      storage: window.sessionStorage
    })
  ]
})

import Vue from 'vue'

const formatDateTime = function (datetime) {
  const parsedDateTime = new Date(datetime)

  return (
    parsedDateTime.getUTCFullYear() +
    '/' +
    ('0' + (parsedDateTime.getUTCMonth() + 1)).slice(-2) +
    '/' +
    ('0' + parsedDateTime.getUTCDate()).slice(-2) +
    ' ' +
    ('0' + parsedDateTime.getUTCHours()).slice(-2) +
    ':' +
    ('0' + parsedDateTime.getUTCMinutes()).slice(-2) +
    ':' +
    ('0' + parsedDateTime.getUTCSeconds()).slice(-2)
  )
}

Vue.filter('formatDateTime', formatDateTime)

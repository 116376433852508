<template>
  <v-overlay :value="$store.state.loadingCircular.start">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
    <div
      class="loadingText"
      v-if="$store.state.loadingCircular.text"
      v-html="$store.state.loadingCircular.text"
    ></div>
  </v-overlay>
</template>

<script>
export default {}
</script>

<style lang="less">
.v-overlay {
  z-index: 101 !important;
}
.v-overlay__content {
  text-align: center;
}
.loadingText {
  padding: 30px 0 0;
  text-align: center;
  font-size: 20px;
}
</style>

<template>
  <v-dialog
    v-model='state'
    content-class='sign-alert'
    persistent
  >
    <div class="sign">
      <div class="container">
        <div v-if="page === '' || page === 'login'" class="sign-in">
          <div class="sign-in__image">
            <img :src="solutionImage" alt="A.V. Mapping">
          </div>

          <div class="sign-in__wrapper">
            <div class="sign-in__title sign-in__title--mobile">{{ $t('sign.in.__title') }}</div>
            <div class="sign-in__subtitle sign-in__subtitle--mobile">
              <span>{{ $t('sign.in.__linkText1') }}</span>
              <span @click="goSignUp" class="sign-in__subtitle-free">{{ $t('sign.in.__linkText2') }}</span>
            </div>

            <div class="sign-in__form">
              <div class="sign-in__form-item">
                <input type="text" v-model="form.email" :placeholder="$t('sign.in.__email')">
              </div>

              <div class="sign-in__form-item">
                <input
                  type="password"
                  v-model="form.password"
                  :placeholder="$t('sign.in.__pwd')"
                  @keyup.enter="userLogin"
                >
              </div>
            </div>

            <div class="sign-in__forget">
              <div @click="chagePage('forget')">{{ $t('sign.in.__forgetPwd') }}</div>
            </div>

            <div class="sign-in__button">
              <div @click="userLogin" class="sign-in__button-item">Sign In</div>

              <div @click="googleUserLogin" class="sign-in__button-item sign-in__button-item--google">
                <div class="sign-in__button-item__icon">
                  <i class="fab fa-google"></i>
                </div>
                <div>{{ $t('sign.in.__google') }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- 忘記密碼 -->
        <div v-if="page === 'forget'" class="sign-forget">
          <div class="sign-forget__wrapper">
            <div class="sign-forget__title">{{ $t('sign.forget.__title') }}</div>

            <div class="sign-forget__form">
              <div class="sign-forget__form-item">
                <input
                  type="text"
                  v-model="form.forgetEmail"
                  :placeholder="$t('sign.forget.__email')"
                  @keyup.enter="userForgetPassword"
                >
              </div>
            </div>

            <div @click="userForgetPassword" class="sign-forget__button">
              <div class="sign-forget__button-item">{{ $t('sign.forget.__btn') }}</div>
            </div>
          </div>
        </div>

        <div class="loading" :class="{ 'loading--active': loading }">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<!-- <script async defer src="https://accounts.google.com/gsi/client"></script> -->
<script>
/* global google */
import solutionImage from '@/assets/user/section-solution.svg'

export default {
  data () {
    return {
      solutionImage,
      googleClient: null,
      checkPass: '',
      loading: false,
      page: '',
      form: {
        email: '',
        password: '',
        forgetEmail: ''
      }
    }
  },
  computed: {
    state () {
      return this.$store.state.sign.state
    }
  },
  mounted () {
    this.loadGoogleApi()
    this.page = this.$store.state.sign.page
  },
  methods: {
    initForm () {
      return {
        email: '',
        password: '',
        forgetEmail: ''
      }
    },

    chagePage (payload) {
      this.form = this.initForm()
      this.page = payload
    },

    goSignUp () {
      const lang = this.$i18n.locale === 'zh-tw' ? 'zh' : 'en'
      window.location.href = `https://avmapping.co/${lang}/#register`
    },

    userLogin () {
      const { email, password } = this.form

      if (email.trim() === '' || password.trim() === '') {
        this.$store.commit('callAlert', {
          content: this.$t('sign.in.__alert1')
        })

        return
      }

      this.$api.auth
        .signin({ email, password })
        .then((resp) => {
          this.$store.commit('setToken', resp.data.key)
          this.$store.commit('toggleSignView', { state: false })

          const currentPath = this.$store.state.sign.path
          this.$router.replace('/empty-route').then(() => {
            this.$router.replace({ path: currentPath })
          })
        })
        .catch((error) => {
          const message = error.data.message[0]
          switch (message) {
            case 'field missing':
              this.$store.commit('callAlert', {
                content: this.$t('sign.in.__alert1')
              })
              break
            case 'auth fail':
              this.$store.commit('callAlert', {
                content: this.$t('sign.in.__alert2')
              })
              break
            case 'unverified email':
              this.$store.commit('callAlert', {
                content: this.$t('sign.in.__alert3')
              })
              break
          }
        })
    },

    userForgetPassword () {
      this.loading = true

      const { forgetEmail: email } = this.form
      const lang = this.$i18n.locale === 'zh-tw' ? 'zh' : 'en'
      const params = {
        email,
        lang,
        target_url: `https://avmapping.co/${lang}/`
      }

      if (email.trim() === '') {
        this.$store.commit('callAlert', {
          content: this.$t('sign.in.__alert1')
        })

        return
      }

      this.$api.auth.resetPassword(params).then(() => {
        this.$store.commit('callAlert', {
          content: this.$t('sign.forget.__alert'),
          confirmCallback: () => {
            this.loading = false
            this.page = 'login'
          }
        })
      }).catch((error) => {
        if (error.data?.message[0]) {
          this.$store.commit('callAlert', {
            content: error.data.message[0],
            confirmCallback: () => {
              this.loading = false
            }
          })
        }
      })
    },

    loadGoogleApi () {
      const script = document.createElement('script')
      script.src = 'https://accounts.google.com/gsi/client'
      script.onload = () => this.initGoogleClient()
      script.async = true
      document.head.appendChild(script)
    },

    initGoogleClient () {
      this.googleClient = google.accounts.oauth2.initTokenClient({
        client_id: '573993374194-7hpd1gf0tll76qqj5599e3n3a1pb6fcr.apps.googleusercontent.com',
        scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'
      })
    },

    async googleUserLogin () {
      try {
        const tokenResponse = await this.timeoutPromise(new Promise((resolve, reject) => {
          try {
            // Settle this promise in the response callback for requestAccessToken()
            this.googleClient.callback = (resp) => {
              if (resp.error !== undefined) reject(resp)

              this.$api.auth
                .googleLogin(resp.access_token)
                .then((resp) => {
                  this.$store.commit('setToken', resp.data.key)
                  this.$store.commit('toggleSignView', { state: false })

                  const currentPath = this.$store.state.sign.path
                  this.$router.replace('/empty-route').then(() => {
                    this.$router.replace({ path: currentPath })
                  })
                })

              resolve(resp)
            }
            // requesting access token
            this.googleClient.requestAccessToken()
          } catch (error) {
            console.error(error)
            reject(error)
          }
        }), 60 * 1000)
        return tokenResponse
      } catch (error) {
        console.error(error)
        this.$store.commit('callAlert', {
          content: this.$t('sign.error.__alert')
        })
      }
    },

    async timeoutPromise (promise, timeout) {
      let timer
      return Promise.race([
        promise,
        new Promise((resolve, reject) => (timer = setTimeout(reject, timeout)))
      ]).finally(() => clearTimeout(timer))
    }
  }
}
</script>

<style lang="scss" scoped>
:deep() .sign-alert {
  max-width: 990px;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  background-color: rgba(black, 0.2);
  transition: opacity 0.3s;
  pointer-events: none;
  opacity: 0;

  &--active {
    pointer-events: visible;
    opacity: 1;
  }
}

.sign {
  width: 100%;

  .container {
    position: relative;

    background-color: #181818;
    border-radius: 25px;
    padding: 20px;

    @media (max-width: 60px) {
      padding: 10px;
    }
  }

  &-up,
  &-in {
    display: flex;
    gap: 30px;

    padding: {
      top: 60px;
      left: 40px;
      right: 80px;
      bottom: 60px;
    }

    @media (max-width: 799px) {
      align-items: center;
      justify-content: center;
      flex-direction: column-reverse;

      padding: {
        top: 20px;
        left: 10px;
        right: 10px;
        bottom: 20px;
      }
    }

    &__image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(40% - 15px);

      @media (max-width: 799px) {
        margin: {
          top: 10px;
        }
      }

      img {
        display: block;
        width: 100%;
        max-width: 200px;
      }
    }

    &__wrapper {
      width: calc(60% - 15px);

      @media (max-width: 799px) {
        width: 100%;
      }
    }

    &__title {
      font-size: 36px;
      font-weight: 600;
      letter-spacing: 2px;
      color: #d8d8d8;

      @media (max-width: 600px) {
        font-size: 20px;
        text-align: center;
      }
    }

    &__subtitle {
      &-free {
        cursor: pointer;
        padding-left: 5px;
      }

      &--mobile {
        @media (max-width: 799px) {
          display: none;
        }
      }
    }

    &__form {
      padding-top: 30px;

      &-item {
        input {
          display: block;
          width: 100%;
          height: 42px;
          background-color: #2c2c2c;
          font-size: 14px;
          letter-spacing: 1px;
          opacity: 0.8;
          color: #ffffff;
          border: solid 1px rgba(204, 204, 204, 0.6);
          border-radius: 2px;
          margin-bottom: 10px;

          padding: {
            left: 10px;
            right: 10px;
          }

          @media (max-width: 600px) {
            height: 36px;
          }
        }

        &--success {
          color: #30c1b8;
        }

        &--fail {
          color: #30c1b8;
        }
      }
    }

    &__forget {
      cursor: pointer;

      display: flex;
      justify-content: flex-end;
      opacity: 0.8;
      color: #ffffff;
      line-height: 20px;
      margin-bottom: 8px;
    }

    &__button {
      flex-direction: column;

      &-item {
        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 42px;
        border: solid 1px #2e5f87;
        background-color: #04335a;
        font-size: 16px;
        letter-spacing: 1px;
        color: #d8d8d8;
        border-radius: 2px;
        margin-top: 20px;

        @media (max-width: 600px) {
          font-size: 14px;
          height: 36px;
        }

        &--google {
          gap: 5px;

          background-color: #d8d8d8;
          color: #04335a;
        }
      }
    }
  }

  &-forget {
    padding: {
      top: 60px;
      bottom: 60px;
    }

    &__wrapper {
      padding: {
        left: calc((100% - 620px) / 2);
        right: calc((100% - 620px) / 2);
      }
    }

    &__title {
      font-size: 36px;
      font-weight: 600;
      letter-spacing: 2px;
      color: #d8d8d8;
    }

    &__form {
      padding-top: 30px;

      &-item {
        input {
          display: block;
          width: 100%;
          height: 42px;
          background-color: #2c2c2c;
          font-size: 14px;
          letter-spacing: 1px;
          opacity: 0.8;
          color: #ffffff;
          border: solid 1px rgba(204, 204, 204, 0.6);
          border-radius: 2px;
          padding: 10px;
          margin-bottom: 10px;

          @media (max-width: 600px) {
            height: 36px;
          }
        }
      }
    }

    &__button {
      cursor: pointer;

      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 42px;
      border: solid 1px #2e5f87;
      background-color: #04335a;
      font-size: 16px;
      letter-spacing: 2px;
      color: #d8d8d8;
      border-radius: 2px;
      margin-top: 20px;

      @media (max-width: 600px) {
        height: 36px;
      }
    }
  }
}
</style>

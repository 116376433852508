<template>
  <div :class='$style.lack'>
    <div :class='$style.mask' @click='closeLack()'></div>
    <div :class='$style.container'>
      <div :class='$style.image'>
        <div :class='$style.sorry'>{{ $t("filmMusicMapping.step1.lack.__slogan") }}</div>
        <img
          :src='require("@/assets/dynamicAnalysis/sorry.png")'
          alt=''
        />
      </div>
      <div :class='$style.content'>
        <div :class='$style.message' v-html='$t("filmMusicMapping.step1.lack.__message")'></div>
        <div :class='$style.buttons'>
          <!-- 立即分享 -->
          <div
            :class='$style.share'
            @click='copyAndAlert()'
          >
            {{ $t("filmMusicMapping.step1.lack.__share") }}
            <input type='hidden' class='shareMessage' :value='shareMessage' />
          </div>
          <!-- 使用券 -->
          <div
            :class='$style.coupon'
            @click='getCoupon()'
          >{{ $t("filmMusicMapping.step1.lack.__coupon") }}</div>
          <!-- 重新分析 -->
          <div
            :class='$style.analysis'
            @click='closeLack()'
          >{{ $t("filmMusicMapping.step1.lack.__analysis") }}</div>
          <!-- 關閉，前往影像庫 -->
          <div
            :class='$style.close'
            @click='toFilms()'
          >{{ $t("filmMusicMapping.step1.lack.__close") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LackOfMusic',
  data () {
    return {
      shareID: ''
    }
  },
  computed: {
    shareMessage () {
      return this.$i18n.t('filmMusicMapping.step1.lack.__shareMessage', {
        locale: this.locale,
        id: this.shareID
      })
    },
    locale () {
      return this.$i18n.locale === 'en' ? 'en' : 'zh'
    }
  },
  methods: {
    async copyAndAlert () {
      this.$store.commit('startLoadingCircular', {})

      // 取得補償的 promote code，再將資料帶到 i18n 裡面
      await this.getCompensate('share')
        .then(value => {
          this.$store.commit('stopLoadingCircular')
          this.shareID = value.promote_code
        })

      this.copyToClipboard()

      this.$store.commit('callAlert', {
        content: this.$t('filmMusicMapping.step1.lack.__copyMessage')
      })
    },
    copyToClipboard () {
      const message = document.querySelector('.shareMessage')
      message.setAttribute('type', 'text')

      let originalEditable = message.contentEditable
      let originalReadOnly = message.readOnly
      const range = document.createRange()

      originalEditable = true
      originalReadOnly = false
      range.selectNodeContents(message)

      const select = window.getSelection()
      select.removeAllRanges()
      select.addRange(range)

      message.focus()
      message.setSelectionRange(0, -1)

      message.contentEditable = originalEditable
      message.readOnly = originalReadOnly

      document.execCommand('copy')

      message.setAttribute('type', 'hidden')
    },
    async getCoupon () {
      await this.getCompensate('ticket')
        .then(value => {
          if (value) {
            const url = this.$t('filmMusicMapping.step1.lack.__couponUrl')
            window.open(url, '_blank')

            // 取得抵用卷後將畫面帶回 S1，不讓使用者繼續分析
            setTimeout(() => {
              this.$store.commit('toggleLackOfMusic')
              this.$router.push({ name: 'step1' })
            }, 500)
          }
        })
    },
    // 取得補償相關資料，類型有 share, ticket
    async getCompensate (params) {
      const session_id = this.$store.state.analyzer.film.session
      const data = {
        type: params
      }

      return await this.$api.compensate
        .getCompensate(session_id, data)
        .then(resp => {
          if (resp.status >= 200 && resp.status < 300) {
            return resp.data
          }

          // error 執行動作
          if (resp.status === 400) {
            this.switchMessage(resp.data.message)
            return false
          }
        })
    },
    // 帶入 API 回傳的值
    switchMessage (value) {
      switch (value) {
        // 錯誤的補償類型
        case 'The compensate type is missing or not acceptable':
          this.$store.commit('callAlert', {
            content: this.$t('lackOfMusic.__wrongType')
          })
          break
        // 此次分析結果補償的使用券已發送
        case 'The compensate ticket is already sent':
          this.$store.commit('callAlert', {
            content: this.$t('lackOfMusic.__alreadySent')
          })
          break
        // 已達每月獲取補償使用券上限
        case 'The compensate ticket is reach monthly limit':
          this.$store.commit('callAlert', {
            content: this.$t('lackOfMusic.__overLimit')
          })
          break
      }
    },
    closeLack () {
      // 已分析次數超過十次還要繼續分析就歸零
      if (this.$store.state.analyzer.analysisCount > 10) {
        this.$store.commit('analyzer/SET_ANALYSIS_COUNT', 0)
      }

      this.$store.commit('toggleLackOfMusic')
    },
    toFilms () {
      this.$store.commit('toggleLackOfMusic')
      this.$router.push({ name: 'films' })
    }
  }
}
</script>

<style lang="scss" module>
$button-width: 200px;
$button-margin: 10px;
$button-box-width: ($button-width * 2) + ($button-margin * 4);

.lack {
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;

  z-index: 100;

  .mask {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: rgb(33, 33, 33);
    border-color: rgb(33, 33, 33);
    opacity: 0.5;
  }

  .container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    max-width: 1440px;
    width: 100%;

    background-color: #111;
    border-radius: 0.25rem;

    padding: 60px;

    margin: {
      left: 30px;
      right: 30px;
    }

    @media (max-width: 799px) {
      flex-direction: column;
    }

    > div {
      width: 50%;

      @media (max-width: 799px) {
        width: 100%;

        padding: {
          top: 30px;
          bottom: 30px;
        }
      }
    }
  }

  .image {
    position: relative;

    .sorry {
      position: absolute;
      top: 0;

      width: 100%;

      font-size: 50px;
      font-weight: 700;
      letter-spacing: 0.01em;

      text-align: center;
    }

    img {
      display: block;
      max-width: 900px;
      width: 100%;

      margin: {
        left: auto;
        right: auto;
      }

      @media (max-width: 799px) {
        max-width: 80%;
      }
    }
  }

  .message {
    font-size: 18px;
    line-height: 1.7;
    color: rgba(216, 216, 216, 0.95);
    text-align: center;

    padding: {
      top: 15px;
      bottom: 15px;
    }

    @media (max-width: 799px) {
      font-size: 20px;
    }
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    max-width: $button-box-width;

    padding: {
      top: 15px;
      bottom: 15px;
    }

    margin: {
      left: auto;
      right: auto;
    }

    .share,
    .coupon,
    .analysis,
    .close {
      display: flex;
      align-items: center;
      justify-content: center;

      max-width: $button-width;
      width: 100%;
      height: 50px;

      font-size: 18px;
      letter-spacing: 0.1em;
      text-decoration: none;
      color: #181818;

      background-color: #30c1b8;
      border: solid 1px #30c1b8;
      border-radius: 50px;

      user-select: none;
      cursor: pointer;

      margin: {
        top: $button-margin;
        left: auto;
        right: auto;
        bottom: $button-margin;
      }
    }
  }
}
</style>

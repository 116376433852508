import axios from './axios.js'
import store from '../store'

const baseUrl = `${process.env.VUE_APP_API_BASE_URL}/api/users`

export default {
  /**
   * 取得用戶資訊
   */
  getUserInfo (identity) {
    return axios({
      method: 'GET',
      url: `${baseUrl}/user/${identity}/`,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    })
  },
  /**
   * 取得用戶資訊(進階資訊)
   */
  getUserInfo_profile () {
    return axios({
      method: 'GET',
      url: `${baseUrl}/profile/`,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    })
  },
  /**
   * 編輯用戶資訊
   * @param { JSON } obj
   */
  editUserInfo (identity, role, obj) {
    return axios({
      method: 'PATCH',
      url: `${baseUrl}/user/${identity}/${role}/`,
      data: obj,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    })
  }
}

import axios from './axios.js'
import store from '../store'

const baseUrl = `${process.env.VUE_APP_API_BASE_URL}/api/users`

export default {
  /**
   * 登入
   * @param { JSON } data
   */
  signin ({ email, password }) {
    return axios({
      method: 'POST',
      url: `${baseUrl}/login/`,
      data: { email, password }
    })
  },

  /**
   * 登出
   */
  signout () {
    return axios({
      method: 'POST',
      url: `${baseUrl}/logout/`,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    }).then(() => store.dispatch('cleanToken'))
  },

  /**
   * 忘記密碼
   */
  resetPassword (payload) {
    return axios({
      method: 'POST',
      url: `${baseUrl}/password/reset/`,
      data: payload
    })
  },

  /**
   * Google Login
   */
  googleLogin (payload) {
    return axios({
      method: 'POST',
      url: `${process.env.VUE_APP_API_BASE_URL}/account/social/google/`,
      data: { access_token: payload }
    })
  },

  /**
   * Facebook Login
   */
  facebookLogin (payload) {
    return axios({
      method: 'POST',
      url: `${process.env.VUE_APP_API_BASE_URL}/account/social/facebook/`,
      data: { access_token: payload }
    })
  }
}

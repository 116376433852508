import axios from './axios.js'
import store from '../store'

const baseUrl = `${process.env.VUE_APP_API_BASE_URL}/compensate`

export default {
  getCompensate (sessionID, params) {
    return axios({
      method: 'POST',
      url: `${baseUrl}/${sessionID}/`,
      data: params,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    })
  }
}

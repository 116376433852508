const baseSize = 16
function setRem () {
  let scale = 1
  const scrollBarWidth = 0
  const clientWidth = document.documentElement.clientWidth + scrollBarWidth
  if (clientWidth > 1220) {
    scale = 1
  } else if (clientWidth <= 1220 && clientWidth > 960) {
    // scale = clientWidth / 1220
    scale = 0.85
  } else if (clientWidth <= 960 && clientWidth > 600) {
    scale = 0.75
  } else {
    scale = clientWidth / 375
  }
  document.documentElement.style.fontSize = baseSize * scale + 'px'
}
setRem()
window.addEventListener('resize', () => {
  setRem()
})

import axios, { makeFormdata } from './axios.js'

const baseUrl = `${process.env.VUE_APP_API_BASE_URL}/api/`

export default {
  /**
   * 登入
   * @param { JSON } obj
   */
  login (obj) {
    const form = makeFormdata(obj)
    return axios({
      method: 'POST',
      url: `${baseUrl}users/login/`,
      data: form,
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }
}

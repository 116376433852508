/**
 * 格式化時間 (mm:ss)
 * @param {number} time 時間 (毫秒)
 */
function mmss (time) {
  if (isNaN(time)) return '00:00'
  let min = parseInt((time % (1000 * 60 * 60)) / (1000 * 60))
  let sec = parseInt((time % (1000 * 60)) / 1000)
  min = min < 10 ? `0${min}` : min
  sec = sec < 10 ? `0${sec}` : sec
  return `${min}:${sec}`
}
/**
 * 傳入時間+幾日 回傳日期
 * @param {String} time 日期
 * @param {Number}} afterDate 延後幾日
 * ＠return 日期
 */
export const addDate = (time, afterDate) => {
  const timestamp = new Date(time).setDate(new Date(time).getDate() + afterDate)
  return new Date(timestamp).toLocaleDateString()
}
export default {
  mmss
}

import axios from './axios.js'
import store from '../store'
const baseUrl = `${process.env.VUE_APP_API_BASE_URL}/contract`

export default {
  getContractDetail (type) {
    return axios({
      method: 'GET',
      url: `${baseUrl}/${type}/`,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    })
  },

  getContractOption (type) {
    return axios({
      method: 'GET',
      url: `${baseUrl}/${type}/option/`,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    })
  },

  signContract (type, data) {
    return axios({
      method: 'POST',
      url: `${baseUrl}/${type}/sign/`,
      data,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    })
  }
}

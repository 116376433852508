import axios from './axios.js'
import store from '../store'

const baseUrl = `${process.env.VUE_APP_API_BASE_URL}`

export default {
  getWallet (identity, currency) {
    return axios({
      method: 'GET',
      url: `${baseUrl}/wallet/${identity}/`,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json',
        'Avma-Currency': currency
      }
    })
  }
}

import axios from './axios.js'
import store from '../store'
const baseUrl = `${process.env.VUE_APP_API_BASE_URL}`

// const makeFormdata = (data) => {
//   const passData = new FormData()
//   Object.keys(data).forEach((key) => {
//     /** music */
//     if (key === 'music') {
//       data[key].forEach((item) => passData.append(key, item))
//       return false
//     }
//     passData.append(key, data[key])
//   })
//   return passData
// }

export default {
  getTransactionList (identity, params, currency) {
    return axios({
      method: 'GET',
      url: `${baseUrl}/transaction/${identity}/?${params}`,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json',
        'Avma-Currency': currency
      }
    })
  },
  getOrderList (payload) {
    return axios({
      method: 'GET',
      url: `${baseUrl}/order/?${payload}`,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    })
  },
  getPrice (film_id, music_id) {
    return axios({
      method: 'POST',
      url: `${baseUrl}/pricing/`,
      data: { film: film_id, music: music_id },
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    })
  },
  getOrder (id) {
    return axios({
      method: 'GET',
      url: `${baseUrl}/order/${id}/`,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    })
  },
  getOrderResult (id, currency) {
    return axios({
      method: 'GET',
      url: `${baseUrl}/order/result/${id}/`,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json',
        'Avma-Currency': currency
      }
    })
  },
  makeOrder (data, currency) {
    return axios({
      method: 'POST',
      url: `${baseUrl}/make_order/`,
      data,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json',
        'Avma-Currency': currency
      }
    })
  },
  /**
   * 新版金流
   */
  newMakeOrder (data, currency) {
    return axios({
      method: 'POST',
      url: `${baseUrl}/order/music/`,
      data,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json',
        'Avma-Currency': currency
      }
    })
  },
  /**
   * 更新配樂標題
   */
  modifyMusicItem (id, data) {
    return axios({
      method: 'POST',
      url: `${baseUrl}/order/music/update-item/`,
      data,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    })
  },
  /**
   * V2 Checkout 取得使用者資訊
   */
  getUserProfile (currency) {
    return axios({
      method: 'GET',
      url: `${baseUrl}/order/music/`,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json',
        'Avma-Currency': currency
      }
    })
  },
  /**
   * 取得使用優惠碼後的商品資訊
   */
  postPromoCode (data, currency) {
    return axios({
      method: 'POST',
      url: `${baseUrl}/order/music/promocode-preview/`,
      data,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json',
        'Avma-Currency': currency
      }
    })
  },
  /**
   * 下載訂單的檔案(合約、音樂檔案)
   * 透過 type 來區分使用的 API
   * type: license, files
   */
  downloadOrderItem (id, type) {
    return axios({
      method: 'GET',
      responseType: 'blob',
      url: `${baseUrl}/order/result/${id}/download-${type}/`,
      headers: {
        Authorization: `Token ${store.state.token}`
      }
    })
  },
  /**
   * 下載混音檔案
   */
  fetchMixerRecordUrl (url) {
    return axios({
      method: 'GET',
      responseType: 'blob',
      url,
      headers: {
        Authorization: `Token ${store.state.token}`
      }
    })
  },
  /**
   * 取消訂閱會員方案
   */
  cancelSubscribe (data) {
    return axios({
      method: 'POST',
      url: `${baseUrl}/order/subscription/cancel/`,
      data,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    })
  }
}

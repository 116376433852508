import axios from './axios.js'
import store from '../store'

const baseUrl = `${process.env.VUE_APP_API_BASE_URL}/exchange_download_url`

export default {
  /**
   * 取得選單
   * @param { JSON } data
   */
  getUrl (filename, kind) {
    // console.log('file name', filename)
    return axios({
      method: 'GET',
      url: `${baseUrl}/?filename=${filename}&type=${kind}`,
      headers: {
        Authorization: `Token ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    })
  }
}
